import "./App.css";

import LeRoutes from "./Routes";
const App = () => {
  return (
    <div className='App'>
      <LeRoutes />
    </div>
  );
};

export default App;
