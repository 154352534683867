import React from "react";

const Links = () => {
  return (
    <div>
      <h1>The Links</h1>
    </div>
  );
};

export default Links;
